import {
  StyledDiv,
  StyledFlexColumnDiv,
  StyledFlexRowDiv,
  StyledIcon,
  StyledText,
} from "./styled";

import { Typography, Icons } from "@flash-tecnologia/hros-web-ui-v2";

import { useNavigate } from "react-router-dom";

const InfoBox = ({ type, setState }) => {
  const actions = {
    pending: {
      title: "Você tem mais de uma fatura com pendências",
      text: "Caso não esteja conseguindo regularizar seu pagamento, entre em contato com nossa equipe de atendimento por e-mail: empresa@flashapp.com.br ou telefone: (11) 4118-0488.",
      buttonText: "Alterar método de pagamento",
      action: "/billing/payment-method",
    },
    paymentError: {
      title: "Ocorreu um erro no pagamento da sua fatura",
      text: "Para regularizar sua fatura, altere o cartão registrado para um válido. Todos os pagamentos pendentes serão debitados automaticamente após a troca.",
      buttonText: "Alterar método de pagamento",
      action: "/billing/payment-method",
    },
  };

  const navigate = useNavigate();
  return (
    <StyledDiv>
      <StyledFlexRowDiv>
        <StyledIcon name="IconCircleX" fill="transparent" size={24} />
        <StyledFlexColumnDiv>
          <StyledText variant="body3" style={{ fontWeight: 600 }}>
            {actions?.[type]?.title}
          </StyledText>
          <StyledText variant="body4"> {actions[type].text}</StyledText>
          <Typography
            variant="body4"
            style={{
              fontWeight: 700,
              color: "#83727D",
              cursor: "pointer",
              marginTop: "6px",
            }}
            onClick={() => navigate(actions[type].action)}
          >
            {actions?.[type]?.buttonText}
          </Typography>
        </StyledFlexColumnDiv>
      </StyledFlexRowDiv>
      <div style={{ cursor: "pointer" }}>
        <Icons
          name="IconX"
          fill="transparent"
          size={18}
          color="#C96C01"
          onClick={() => setState(false)}
        />
      </div>
    </StyledDiv>
  );
};

export { InfoBox };
