import { Icons, LinkButton, Tooltip } from "@flash-tecnologia/hros-web-ui-v2";
import { segment } from "@utils";
import { useNavigate } from "react-router-dom";
import {
  StyledBox,
  StyledCircle,
  StyledDiv,
  StyledDivider,
  StyledDivRow,
  StyledText,
  StyledTitle,
} from "./styled";

const SubscriptionDetailsBox = ({
  employees,
  price,
  endDate,
  billingDate,
  type,
  segmentTrack,
  hasClosedInvoice,
}: {
  employees?: any;
  price: string;
  endDate: string;
  billingDate: string;
  type: string;
  segmentTrack?: any;
  hasClosedInvoice?: boolean;
}) => {
  const navigate = useNavigate();
  const subscriptionStatus = {
    open: {
      title: "Fatura aberta",
    },
    canceled: {
      title: "Fatura Cancelada",
      buttonText: "Assinar novamente",
    },
  };
  return (
    <StyledBox>
      <StyledDiv style={{ gap: "25px" }}>
        <StyledTitle variant="body3" style={{ fontWeight: 700 }}>
          {subscriptionStatus?.[type]?.title}
        </StyledTitle>
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row-reverse",
              justifyContent: "flex-end",
            }}
          >
            <StyledCircle status={type} />
            <StyledTitle variant="headline5">{employees}</StyledTitle>
          </div>

          <StyledText variant="body4" style={{ whiteSpace: "normal" }}>
            {`pessoas ativadas até o momento`}
          </StyledText>
        </div>
        {type == "canceled" && (
          <LinkButton
            variant="primary"
            onClick={() => {
              navigate("/people/acquisition/new-acquisition/payment");
              segment({
                track:
                  "company_configurations_finance_paymentsandbilling_subscriptioncanceled__subscribeagain_linkbutton_clicked",
              });
            }}
          >
            Assinar novamente
          </LinkButton>
        )}
        {/* {type == "open" && (
          <LinkButton
            variant="primary"
            onClick={() => {
              navigate(`/billing/invoice/open`);
              segment({
                track: `company_configurations_finance_paymentsandbilling_${segmentTrack}_checkdetails_linkbutton_clicked`,
              });
            }}
          >
            Ver detalhes
          </LinkButton>
        )} */}
      </StyledDiv>

      {type != "canceled" && (
        <>
          {hasClosedInvoice && (
            <StyledDivider orientation="vertical" flexItem={true} />
          )}

          <StyledDiv
            style={{
              gap: "24px",
              marginTop: "12px",
              flexDirection: hasClosedInvoice ? "column" : "row",
              height: "100%",
              alignItems: hasClosedInvoice ? "flex-start" : "center",
              justifyContent: "center",
            }}
          >
            <StyledDiv>
              <StyledTitle variant="body3" style={{ fontWeight: 700 }}>
                R$ {price}
              </StyledTitle>
              <StyledDivRow>
                <StyledText variant="body4" style={{ marginRight: "4px" }}>
                  Valor até o momento
                </StyledText>
                <Tooltip
                  title={
                    employees > 10
                      ? "Esse valor pode sofrer alterações até a data de fechamento."
                      : "Você tem menos de 10 colaboradores e será cobrado o valor mínimo."
                  }
                  placement="right-start"
                >
                  <button style={{ cursor: "default" }}>
                    <Icons
                      name="IconInfoCircle"
                      fill="transparent"
                      color="#83727D"
                      size={16}
                    />
                  </button>
                </Tooltip>
              </StyledDivRow>
            </StyledDiv>
            <StyledDivRow style={{ gap: "41px" }}>
              <StyledDiv>
                <StyledTitle variant="body3" style={{ fontWeight: 700 }}>
                  {endDate}
                </StyledTitle>
                <StyledText variant="body4">Data de fechamento</StyledText>
              </StyledDiv>
              <StyledDiv>
                <StyledTitle variant="body3" style={{ fontWeight: 700 }}>
                  {billingDate}
                </StyledTitle>
                <StyledText variant="body4">Data de cobrança</StyledText>
              </StyledDiv>
            </StyledDivRow>
          </StyledDiv>
        </>
      )}
    </StyledBox>
  );
};

export { SubscriptionDetailsBox };
