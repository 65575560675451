import { trpc } from "@api/client";
import { DataGridFooter } from "@components/DataGridFooter";
import {
  Checkbox,
  Icons,
  LinkButton,
  Menu,
} from "@flash-tecnologia/hros-web-ui-v2";
import { dispatchToast, segment } from "@utils";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  StyledDatagrid,
  StyledDiv,
  StyledFiltertag,
  StyledFlex,
  StyledFlexRow,
  StyledIcons,
  StyledSearchField,
  StyledTableText,
  StyledTag,
  StyledTagCheckboxFilter,
  StyledText,
  SubImageIcon,
  WhiteSpacePreDiv,
} from "./styled";

import { PDFDocument } from "pdf-lib";

const BillingHistoric = ({
  segmentTrack,
  invoices,
}: {
  segmentTrack?: any;
  invoices: any;
}) => {
  const navigate = useNavigate();

  const generateExtract = trpc.generateExtract.useMutation({
    onSuccess: async (data) => {
      const uint8Array = new Uint8Array(Object.values(data?.pdf));

      const pdfDoc = await PDFDocument.load(uint8Array);

      const pdfBytes = await pdfDoc.save();

      const pdfUrl = URL.createObjectURL(
        new Blob([pdfBytes], { type: "application/pdf" })
      );

      const a = document.createElement("a");
      a.href = pdfUrl;
      a.download = data?.filename || "extrato_flashpeople.pdf";
      a.textContent = "Clique para baixar o PDF";

      document.body.appendChild(a);
      a.click();

      URL.revokeObjectURL(pdfUrl);

      dispatchToast({
        content: "Sucesso",
        type: "success",
      });
    },
    onError: () => {
      dispatchToast({
        content: "Sentimos muito, ocorreu um erro. Tente novamente mais tarde",
        type: "error",
      });
    },
  });

  if (generateExtract?.isLoading) {
    dispatchToast({
      content: "Baixando PDF...",
      type: "warning",
    });
  }

  const sendEmail = trpc.sendEmail.useMutation({
    onSuccess: async () => {
      dispatchToast({
        content: "E-mail enviado com sucesso!",
        type: "success",
      });
    },
    onError: () => {
      dispatchToast({
        content:
          "Sentimos muito, ocorreu um erro. Verifique se possui e-mails cadastrados, ou tente novamente.",
        type: "error",
      });
    },
  });

  if (sendEmail?.isLoading) {
    dispatchToast({
      content: "Enviando e-mail...",
      type: "warning",
    });
  }

  const BillingOptions = ({ billingId, status }) => {
    const options = useMemo(() => {
      const opt: { onClick: () => void; children: JSX.Element }[] = [];

      const obj = {
        details: {
          onClick: () => {
            switch (true) {
              case status == "open":
                segment({
                  track: `company_configurations_finance_paymentsandbilling_${segmentTrack}_billingrecord_openstatus_icon_verticaldots_button_seedetails_clicked`,
                });
                break;
              case status == "processing":
                segment({
                  track: `company_configurations_finance_paymentsandbilling_${segmentTrack}_billingrecord_inprocessstatus_icon_verticaldots_button_seedetails_clicked`,
                });
                break;
              case status == "paid":
                segment({
                  track: `company_configurations_finance_paymentsandbilling_${segmentTrack}_billingrecord_paidstatus_icon_verticaldots_button_seedetails_clicked`,
                });
                break;
              case status == "error":
                segment({
                  track: `company_configurations_finance_paymentsandbilling_${segmentTrack}billingrecord_paymenterrorstatus_icon_verticaldots_button_seedetails_clicked`,
                });
                break;
            }
            navigate(`/billing/invoice/${billingId}`);
          },
          children: (
            <StyledFlex>
              <Icons
                fill="transparent"
                name="IconFileDescription"
                color="#53464F"
              />
              <StyledTableText variant="body3" style={{ fontWeight: 600 }}>
                Ver detalhes
              </StyledTableText>
            </StyledFlex>
          ),
        },
        email: {
          onClick: () => {
            switch (true) {
              case status == "paid":
                segment({
                  track: `company_configurations_finance_paymentsandbilling_${segmentTrack}_billingrecord_paidstatus_icon_verticaldots_button_sendinvoice_clicked`,
                });
                break;
              case status == "open":
                segment({
                  track: `company_configurations_finance_paymentsandbilling_${segmentTrack}_billingrecord_openstatus_icon_verticaldots_button_sendinvoice_clicked`,
                });
                break;
              case status == "processing":
                segment({
                  track: `company_configurations_finance_paymentsandbilling_${segmentTrack}_billingrecord_inprocessstatus_icon_verticaldots_button_sendinvoice_clicked`,
                });
                break;
              case status == "error":
                segment({
                  track: `company_configurations_finance_paymentsandbilling_${segmentTrack}_billingrecord_paymenterrorstatus_icon_verticaldots_button_seedetails_clicked`,
                });
                break;
            }

            sendEmail.mutate({ invoiceId: billingId });
          },
          children: (
            <StyledFlex>
              <Icons
                fill="transparent"
                name="IconMailForward"
                color="#53464F"
              />
              <StyledTableText variant="body3" style={{ fontWeight: 600 }}>
                Enviar extrato por e-mail
              </StyledTableText>
            </StyledFlex>
          ),
        },
        pdf: {
          onClick: () => {
            switch (true) {
              case status == "paid":
                segment({
                  track: `company_configurations_finance_paymentsandbilling_${segmentTrack}_billingrecord_paidstatus_icon_verticaldots_button_downloadcsv_clicked`,
                });
                break;
              case status == "open":
                segment({
                  track: `company_configurations_finance_paymentsandbilling_${segmentTrack}_billingrecord_openstatus_icon_verticaldots_button_downloadcsv_clicked`,
                });
                break;
              case status == "processing":
                segment({
                  track: `company_configurations_finance_paymentsandbilling_${segmentTrack}_billingrecord_inprocessstatus_icon_verticaldots_button_downloadcsv_clicked`,
                });
                break;
              case status == "error":
                segment({
                  track: `company_configurations_finance_paymentsandbilling_${segmentTrack}_billingrecord_paymenterrorstatus_icon_verticaldots_button_downloadcsv_clicked`,
                });
                break;
            }
            generateExtract.mutate({ invoiceId: billingId });
          },
          children: (
            <StyledFlex>
              <Icons fill="transparent" name="IconDownload" color="#53464F" />
              <StyledTableText variant="body3" style={{ fontWeight: 600 }}>
                Baixar extrato em PDF
              </StyledTableText>
            </StyledFlex>
          ),
        },
        // nfe: {
        //   onClick: () => {
        //     switch (true) {
        //       case status == "paid":
        //         segment({
        //           track: `company_configurations_finance_paymentsandbilling_${segmentTrack}_billingrecord_paidstatus_icon_verticaldots_button_downloadnfe_clicked`,
        //         });
        //         break;
        //       case status == "open":
        //         segment({
        //           track: `company_configurations_finance_paymentsandbilling_${segmentTrack}_billingrecord_paidstatus_icon_verticaldots_button_seedetails_clicked`,
        //         });
        //         break;
        //       case status == "processing":
        //         segment({
        //           track: `company_configurations_finance_paymentsandbilling_${segmentTrack}_billingrecord_paidstatus_icon_verticaldots_button_seedetails_clicked`,
        //         });
        //         break;
        //       case status == "error":
        //         segment({
        //           track: `company_configurations_finance_paymentsandbilling_${segmentTrack}_billingrecord_paidstatus_icon_verticaldots_button_seedetails_clicked`,
        //         });
        //         break;
        //     }
        //   },
        //   children: (
        //     <StyledFlex>
        //       <Icons fill="transparent" name="IconDownload" color="#53464F" />
        //       <StyledTableText variant="body3" style={{ fontWeight: 600 }}>
        //         Baixar NFe
        //       </StyledTableText>
        //     </StyledFlex>
        //   ),
        // },
        card: {
          onClick: () => {
            navigate(`/billing/payment-method`);
          },
          children: (
            <StyledFlex>
              <Icons
                fill="transparent"
                name="IconSwitchHorizontal"
                color="#53464F"
              />
              <StyledTableText variant="body3" style={{ fontWeight: 600 }}>
                Alterar método de pagamento
              </StyledTableText>
            </StyledFlex>
          ),
        },
      };
      switch (status) {
        case "paid":
          // opt.push(obj?.details, obj?.email, obj?.pdf, obj.nfe);
          opt.push(obj?.details, obj?.email, obj?.pdf);
          break;
        case "error":
          opt.push(obj?.card, obj?.details, obj?.email, obj?.pdf);
          break;
        case "refused":
          opt.push(obj?.details);
          break;
        case "refunded":
          opt.push(obj?.details, obj?.email, obj?.pdf);
          break;
        default:
          opt.push(obj?.details, obj?.email, obj?.pdf);
          break;
      }

      return opt;
    }, [status, billingId]);

    return (
      <Menu
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        type="select"
        options={options as []}
      >
        <div
          style={{
            width: "100%",
            alignItems: "center",
            display: "flex",
            justifyContent: "flex-start",
            paddingRight: "12px",
          }}
        >
          <SubImageIcon
            onClick={() => {
              segment({
                track: `company_configurations_finance_paymentsandbilling_${segmentTrack}_billingrecord_icon_verticaldots_clicked`,
              });
            }}
            hasNoImage={true}
            size="small"
            variant="filled"
            icon="IconDotsVertical"
          />
        </div>
      </Menu>
    );
  };
  const filterByStatus = [
    {
      label: "Pago",
      value: "paid",
    },
    {
      label: "Reembolsado",
      value: "refunded",
    },
    {
      label: "Erro no pagamento",
      value: "error",
    },
    {
      label: "Cancelado",
      value: "refused",
    },
    {
      label: "Em aberto",
      value: "open",
    },
  ];

  const [allFilters, setAllFilters] = useState(invoices);
  const [filterStates, setFilterStates] = useState(0);

  const [selectedBillings, setSelectedBillings] = useState(new Set());
  const allSelected = allFilters?.every((billing) =>
    selectedBillings?.has(billing?._id)
  );

  const handleSingleSelect = (billingId) => {
    const newSelected = new Set(selectedBillings);

    selectedBillings?.has(billingId)
      ? newSelected?.delete(billingId)
      : newSelected?.add(billingId);

    setSelectedBillings(newSelected);
  };

  const handleTotalSelect = () => {
    const newSelected = new Set();

    allSelected
      ? allFilters?.forEach((billing) => newSelected?.delete(billing?._id))
      : allFilters?.forEach((billing) => newSelected?.add(billing?._id));

    setSelectedBillings(newSelected);
  };

  const columns = useMemo(
    () => [
      // {
      //   Header: (
      //     <Checkbox
      //       onChange={() => {
      //         handleTotalSelect();
      //       }}
      //       checked={allSelected}
      //     />
      //   ),
      //   accessor: "_id",
      //   disableSortBy: true,
      //   Cell: ({ value }) => (
      //     <Checkbox
      //       checked={selectedBillings.has(value)}
      //       onChange={() => {
      //         handleSingleSelect(value);
      //       }}
      //     />
      //   ),
      // },
      {
        Header: selectedBillings?.size ? (
          <StyledTag variant="primary">
            {selectedBillings?.size} de {allFilters?.length} selecionados
          </StyledTag>
        ) : (
          <WhiteSpacePreDiv>Referente a</WhiteSpacePreDiv>
        ),
        disableSortBy: true,

        accessor: "month",
        Cell: ({
          row: {
            original: { referenceDate = null },
          },
        }) => {
          if (referenceDate) {
            return (
              <StyledTableText variant="body3">{referenceDate}</StyledTableText>
            );
          }
          return <></>;
        },
      },
      {
        Header: selectedBillings?.size ? (
          <LinkButton variant="secondary">
            <WhiteSpacePreDiv>Baixar extratos em PDF</WhiteSpacePreDiv>
          </LinkButton>
        ) : (
          <WhiteSpacePreDiv>Status de pagamento</WhiteSpacePreDiv>
        ),
        disableSortBy: true,

        accessor: "status",
        Cell: ({
          row: {
            original: { status },
          },
        }) => {
          let tag = <div></div>;
          if (status) {
            switch (status) {
              case "paid":
                tag = (
                  <StyledTag hasLeftDotIcon={true} variant="success">
                    Pago
                  </StyledTag>
                );
                break;
              case "error":
                tag = (
                  <StyledTag hasLeftDotIcon={true} variant="error">
                    Erro de pagamento
                  </StyledTag>
                );
                break;
              case "refused":
                tag = (
                  <StyledTag hasLeftDotIcon={true} variant="gray">
                    Cancelado
                  </StyledTag>
                );
                break;
              case "refunded":
                tag = (
                  <StyledTag hasLeftDotIcon={true} variant="gray">
                    Reembolsado
                  </StyledTag>
                );
                break;
              case "open":
                tag = (
                  <StyledTag hasLeftDotIcon={true} variant="blue">
                    Em aberto
                  </StyledTag>
                );
                break;
              default:
                tag = (
                  <StyledTag hasLeftDotIcon={true} variant="gray">
                    Fechado
                  </StyledTag>
                );
                break;
            }
            return <StyledFlexRow>{tag}</StyledFlexRow>;
          }
          return <></>;
        },
      },
      {
        Header: selectedBillings?.size ? (
          <LinkButton variant="secondary">
            <WhiteSpacePreDiv>Baixar notas fiscais</WhiteSpacePreDiv>
          </LinkButton>
        ) : (
          "Valor"
        ),
        disableSortBy: true,
        accessor: "price",
        Cell: ({
          row: {
            original: { amount = null },
          },
        }) => {
          if (amount) {
            return (
              <StyledFlexRow>
                <StyledTableText variant="body3">{amount}</StyledTableText>
              </StyledFlexRow>
            );
          }
          return <></>;
        },
      },
      {
        Header: !selectedBillings?.size && (
          <WhiteSpacePreDiv>Pessoas ativas</WhiteSpacePreDiv>
        ),
        disableSortBy: true,
        accessor: "employees",
        Cell: ({
          row: {
            original: { employees = null },
          },
        }) => {
          if (employees) {
            return (
              <StyledFlexRow>
                <StyledTableText variant="body3">{employees}</StyledTableText>
              </StyledFlexRow>
            );
          }
          return <></>;
        },
      },
      {
        Header: !selectedBillings?.size && (
          <WhiteSpacePreDiv>Data de cobrança</WhiteSpacePreDiv>
        ),
        accessor: "billingDate",
        disableSortBy: true,
        Cell: ({
          row: {
            original: { dueDate = null },
          },
        }) => {
          if (dueDate) {
            return (
              <StyledFlexRow>
                <StyledTableText variant="body3">{dueDate}</StyledTableText>
              </StyledFlexRow>
            );
          }
          return <></>;
        },
      },
      {
        Header: !selectedBillings?.size && "Pagamento",
        disableSortBy: true,
        accessor: "paymentType",
        Cell: ({
          row: {
            original: { paymentType = null },
          },
        }) => {
          if (paymentType) {
            return (
              <StyledFlexRow>
                {/* <StyledIcons
                  name="IconCreditCard"
                  fill="transparent"
                  color="#53464F"
                /> */}
                <StyledTableText style={{ marginLeft: "4px" }} variant="body3">
                  {paymentType}
                </StyledTableText>
              </StyledFlexRow>
            );
          }
          return <></>;
        },
      },

      {
        Header: "Ações",
        sticky: "right",
        disableSortBy: true,
        Cell: ({ row: { original } }) => {
          const { _id, status } = original;
          if (_id && status) {
            return <BillingOptions billingId={_id} status={status} />;
          }
          return <></>;
        },
      },
    ],
    [allFilters, allSelected, selectedBillings]
  );

  const handleFilteredContent = (values) => {
    const billings: any[] = [];

    if (values?.length) {
      invoices?.map((b) => {
        values?.some((s) => {
          if (b?.status == s) {
            billings?.push(b);
          }
        });
      });
      values?.map((v) => {
        switch (true) {
          case v == "open":
            segment({
              track: `company_configurations_finance_paymentsandbilling_${segmentTrack}_filterstatus_option_open_clicked`,
            });
            break;
          case v == "closed":
            segment({
              track: `company_configurations_finance_paymentsandbilling_${segmentTrack}_filterstatus_option_closed_clicked`,
            });
            break;
          case v == "paid":
            segment({
              track: `company_configurations_finance_paymentsandbilling_${segmentTrack}_filterstatus_option_paid_clicked`,
            });
            break;
        }
      });
      setAllFilters(billings);
    } else {
      setAllFilters(invoices);
    }
  };

  const onSearch = (value: string) => {
    const result = invoices?.filter(
      (item) =>
        item?.referenceDate?.match(RegExp(value, "ig")) ||
        item?.dueDate?.match(RegExp(value, "ig"))
    );
    setAllFilters([...result]);
  };

  useEffect(() => {
    setAllFilters(invoices);
  }, []);

  return (
    <StyledDiv>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <StyledText variant="headline8" style={{ marginRight: "4px" }}>
          Histórico de cobranças
        </StyledText>
        <StyledTag variant="gray">
          {invoices?.length == 1
            ? invoices?.length + " item" || "0" + " itens"
            : invoices?.length + " itens" || "0" + " itens"}
        </StyledTag>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "12px",
        }}
      >
        <StyledTableText variant="body3">Filtrar por </StyledTableText>

        <StyledTagCheckboxFilter
          showClearButton={true}
          badgeNumber={filterStates}
          showSelectAllButton={true}
          hasLeftIcon={false}
          filterLabel={"Status de pagamento"}
          variant={"secondary"}
          onClick={(values) => {
            handleFilteredContent(values);
            setFilterStates(values?.length);
          }}
          options={filterByStatus}
          onBlur={() =>
            segment({
              track: `company_configurations_finance_paymentsandbilling_${segmentTrack}_filter_status_clicked`,
            })
          }
        />

        {/* <StyledFiltertag
          hasLeftIcon={false}
          onApply={(values) => {
            handleFilteredContent(values);
          }}
          onClear={() => {
            handleFilteredContent([]);
          }}
          options={filterByStatus}
          optionIconType={"checkbox"}
          variant={"secondary"}
          filterLabel={"Status de pagamento"}
          onBlur={() =>
            segment({
              track: `company_configurations_finance_paymentsandbilling_${segmentTrack}_filter_status_clicked`,
            })
          }
        /> */}
      </div>

      <StyledSearchField
        placeholder="Buscar por cobranças"
        label={"Buscar por cobranças"}
        onChange={(e) => {
          onSearch(e?.target?.value);
        }}
      />
      <StyledDatagrid
        columns={columns as any}
        data={allFilters || []}
        hasPagination={true}
        pageSizeOptions={[
          { label: "5 itens", value: 5 },
          { label: "10 itens", value: 10 },
          { label: "20 itens", value: 20 },
        ]}
      />
    </StyledDiv>
  );
};

export { BillingHistoric };
